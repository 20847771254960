import React, { useEffect } from 'react'
import BreadCrumb from "../components/BreadCrumb"
import Meta from "../components/Meta";
import { Link, useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import CustomInput from '../components/CustomInput'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../features/user/userSlice';


const signUpSchema = yup.object().shape({
    firstname: yup
    .string().required("Le prénom est requis"),
    lastname: yup
    .string().required("Le nom de famille est obligatoire"),
    email: yup
      .string()
      .email("L'e-mail doit être valide")
      .required("L'e-mail est requis"),
      mobile: yup
      .string().required("Le mobile est requis"),
    password: yup.string().required("Mot de passe requis"),
  });
const SignUp = () =>{
const dispatch = useDispatch();
const authState = useSelector(state => state.auth )
const navigate  = useNavigate()
    const formik = useFormik({
        initialValues: {
            firstname:"",
            lastname:"",
          email: "",
          mobile:"",
          password: "",
        },
        validationSchema: signUpSchema,
        onSubmit: (values) => {
 dispatch(registerUser(values))
        },
      });
 
      useEffect(() => {
        if (authState.createUser !== undefined  && authState.isError === false){
navigate("/login")
        }
        },[authState])

    return(<><Meta title={"Sign Up"} />
    <BreadCrumb title="Sign Up" />
    <Container class1="login-wrapper py-5 home-wrapper-2"> <div className='row'>
            <div className='col-12'>
                <div className='auth-card'>
                    <h3 className='text-center mb-3'>Sign Up</h3>
                    <form action="" onSubmit={formik.handleSubmit} className='d-flex flex-column gap-15'>
                    <CustomInput  type='text' name='firstname' value={formik.values.firstname} onChange={formik.handleChange("firstname")}
                     onBlur={formik.handleBlur("firstname")} placeholder='Prénom' />
                       <div className="error">
            {formik.touched.firstname && formik.errors.firstname}
          </div>
                    <CustomInput  type='text' name='lastname' value={formik.values.lastname} onChange={formik.handleChange("lastname")} 
                    onBlur={formik.handleBlur("lastname")} placeholder='Nom' />
                           <div className="error ">
            {formik.touched.lastname && formik.errors.lastname}
          </div>
                        <CustomInput type='email' name='email'value={formik.values.email}  onChange={formik.handleChange("email")} 
                       onBlur={formik.handleBlur("email")}    placeholder='Email' />
                                   <div className="error ">
            {formik.touched.email && formik.errors.email}
          </div>
                        <CustomInput type='tel' name='mobile'value={formik.values.mobile} onChange={formik.handleChange("mobile")}  
                    onBlur={formik.handleBlur("mobile")}      placeholder='Mobile'/>
                                     <div className="error ">
            {formik.touched.mobile && formik.errors.mobile}
          </div>
                        <CustomInput type='password' name='password'value={formik.values.password} onChange={formik.handleChange("password")}
                      onBlur={formik.handleBlur("password")}     placeholder='Mot de passe'/>
                                               <div className="error ">
            {formik.touched.password && formik.errors.password}
          </div>
                        <div> 
                        <div className='mt-3 d-flex justify-content-center gap-15 align-items-center'>
                            <button className='button border-0'>Sign Up</button>
                        
                        </div>
                        
                        </div>
                    </form>
                    
                    
                    </div></div></div></Container>
  </>)
}
export default SignUp